/**
 * @module menu-items/reviewer
 * @description
 * The `reviewer` object defines the structure of the reviewer dashboard's navigation. It includes a
 * group with a single item for the dashboard. Each item has properties for its unique `id`, display
 * `title`, route `url`, associated `icon`, and whether it has `breadcrumbs`.
 * The icon for the dashboard item is imported as a React component from the SVG file `panel-my-people.svg`.
 * This module can be used to dynamically render the reviewer dashboard navigation menu.
 * @constant {Object} reviewer
 * @property {string} id - The unique identifier for the reviewer dashboard group.
 * @property {string} type - The type of this menu item, in this case, it's a group.
 * @property {Array} children - The list of menu items within the group.
 * @property {Object} children[].id - The unique identifier for the individual menu item.
 * @property {string} children[].title - The display title of the menu item.
 * @property {string} children[].url - The URL that the menu item links to.
 * @property {ReactComponent} children[].icon - The icon to be displayed alongside the menu item.
 * @property {boolean} children[].breadcrumbs - Whether or not breadcrumbs are enabled for this menu item.
 */
import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';
import { ReactComponent as DistributionIcon } from 'assets/panel-distribution.svg';

const reviewer = {
  id: 'reviewerDashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/reviewer/reviewerDashboard',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
    {
      id: 'ticketing',
      title: 'Ticketing',
      type: 'item',
      url: '/reviewer/ticketing',
      icon: DistributionIcon,
      breadcrumbs: false
    },
  ]
};

export default reviewer;
