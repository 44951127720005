/**
 * @module MainRoutes
 *
 * This module defines the main routes for the application, which are protected by authentication and authorization logic.
 * It includes routing for different roles such as `admin`, `partner`, `reviewer`, and `user`. The routes are nested
 * and organized by various sections of the application such as the profile setup, onboarding flow, dashboard, and admin panel.
 * The routing configuration utilizes `react-router-dom` to render specific components based on the URL path.
 *
 * @see ProtectedRoutes for the authentication checks
 */

import { lazy } from 'react';

// project imports
import ProtectedRoutes from 'helpers/ProtectedRoutes';
import MainLayout from 'layout/MainLayout';
import AdminLandingPage from 'pages/AdminPages/AdminLandingPage';
import CouponsPage from 'pages/AdminPages/CouponsPage';
import ReportsPage from 'pages/AdminPages/ReportsPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import ResourceHub from 'pages/LandingPage/ResourceHub';
import JourneyPage from 'pages/OnboardingFlow/JourneyPage';
import WelcomePage from 'pages/OnboardingFlow/WelcomePage';
import PartnerLandingPage from 'pages/PartnerLandingPage/PartnerLandingPage';
import AddressDetailsPage from 'pages/ProfileProcessPages/AddressDetailsPage';
import ExistingWillDetailsPage from 'pages/ProfileProcessPages/ExistingWillDetailsPage';
import PersonalDetailsPage from 'pages/ProfileProcessPages/PersonalDetailsPage';
import ProfileCompletionPage from 'pages/ProfileProcessPages/ProfileCompletionPage';
import ReviewerDashboardLandingPage from 'pages/ReviewerDashboardLandingPage/ReviewerDashboardLandingPage';
import ServicesPage from 'pages/ServicePage/ServicesPage';
import TicketingLanding from 'pages/TicketingLanding/TicketingLanding';
import { Outlet } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import CommissionReportsPage from 'views/adminDashboard/CommissionReportsPage';
import CompletedWillsPage from 'views/adminDashboard/CompletedWillsPage';
import ConversionRatioPage from 'views/adminDashboard/ConversionRatioPage';
import GstReportsPage from 'views/adminDashboard/GstPaidDetailsPage';
import IncompleteWillsPage from 'views/adminDashboard/IncompleteWillsPage';
import NumberOfClientsPage from 'views/adminDashboard/NumberOfClientsPage';
import PartnersPage from 'views/adminDashboard/PartnersPage';
import RepeatRatePage from 'views/adminDashboard/RepeatRatePage';
import TopPerformerPage from 'views/adminDashboard/TopPerformerPage';
import AssetPage from 'views/AssetPage';
import DistributionPage from 'views/distribution';
import MyWill from 'views/myWill';
import WillGenerationSuccess from 'views/myWill/WillGenerationSuccess';
import CommissionPage from 'views/partnerDashboard/CommissionPage';
import PartnerCompletedWillsPage from 'views/partnerDashboard/PartnerCompletedWillsPage';
import PartnerIncompleteWillsPage from 'views/partnerDashboard/PartnerIncompleteWillsPage';
import PartnerNumberOfClientsPage from 'views/partnerDashboard/PartnerNumberOfClientsPage';
import SignupVsPaidPartnerUsers from 'views/partnerDashboard/SignupVsPaidPartnerUser';
import ProfilePage from 'views/profile';

// Dashboard routing - Lazy loaded
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

/**
 * @constant {object} MainRoutes
 * @description Contains all the route definitions for the main app, including protected routes for various roles (admin, partner, reviewer).
 *
 * MainRoutes integrates authentication checks via `ProtectedRoutes` and organizes routes for user flow, profile setup, admin dashboard, partner dashboard, and more.
 * The `MainRoutes` object is used by `react-router-dom`'s `useRoutes` hook to dynamically map the routes in the application.
 *
 * @type {object}
 * @property {string} path - The root path for the main routing (`'/'`).
 * @property {JSX.Element} element - The top-level component wrapped around the routes for authentication protection (`<ProtectedRoutes />`).
 * @property {Array} children - Array of nested route definitions for the main sections and sub-sections of the application.
 */
const MainRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <Outlet />, // Outlet for nested route elements
      children: [
        {
          path: '/personalDetails',
          element: <PersonalDetailsPage />,
        },
        {
          path: '/addressDetails',
          element: <AddressDetailsPage />,
        },
        {
          path: '/existingDetails',
          element: <ExistingWillDetailsPage />,
        },
        {
          path: '/profileCompletion',
          element: <ProfileCompletionPage />,
        },
        {
          path: '/welcome',
          element: <WelcomePage />,
        },
        {
          path: '/journey',
          element: <JourneyPage />,
        },
        {
          path: '/Services',
          element: <ServicesPage />,
        },
        {
          path: '/landingPage',
          element: <LandingPage />,
        },
        {
          path: '/resourceHub',
          element: <ResourceHub />,
        },
        {
          path: '/willGenerationSuccess',
          element: <WillGenerationSuccess />,
        },
        {
          path: '/partnerLandingPage',
          element: <PartnerLandingPage />,
        },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: '/myPeople',
              element: <DashboardDefault />,
            },
            {
              path: '/assets',
              element: <AssetPage />,
            },
            {
              path: '/distribution',
              element: <DistributionPage />,
            },
            {
              path: '/profilePage',
              element: <ProfilePage />,
            },
            {
              path: '/myWill',
              element: <MyWill />,
            },
            {
              path: '/partner',
              children: [
                {
                  path: 'numberOfClients',
                  element: <PartnerNumberOfClientsPage />,
                },
                {
                  path: 'signupVsPaidUsers',
                  element: <SignupVsPaidPartnerUsers />,
                },
                {
                  path: 'completedWills',
                  element: <PartnerCompletedWillsPage />,
                },
                {
                  path: 'incompleteWills',
                  element: <PartnerIncompleteWillsPage />,
                },
                {
                  path: 'earnings',
                  element: <CommissionPage />,
                },
              ],
            },
            {
              path: '/admin',
              children: [
                {
                  path: 'analyticaldashboard',
                  element: <AdminLandingPage />,
                },
                {
                  path: 'noOfClients',
                  element: <NumberOfClientsPage />,
                },
                {
                  path: 'partnerDetails',
                  element: <PartnersPage />,
                },
                {
                  path: 'topPerformer',
                  element: <TopPerformerPage />,
                },
                {
                  path: 'conversionRatio',
                  element: <ConversionRatioPage />,
                },
                {
                  path: 'repeatRate',
                  element: <RepeatRatePage />,
                },
                {
                  path: 'commissionReports',
                  element: <CommissionReportsPage />,
                },
                {
                  path: 'gstReports',
                  element: <GstReportsPage />,
                },
                {
                  path: 'completedWills',
                  element: <CompletedWillsPage />,
                },
                {
                  path: 'incompleteWills',
                  element: <IncompleteWillsPage />,
                },
                {
                  path: 'coupons',
                  element: <CouponsPage />,
                },
                {
                  path: 'partners',
                  element: <PartnersPage />,
                },
                {
                  path: 'reports',
                  element: <ReportsPage />,
                },
              ],
            },
            {
              path: '/reviewer',
              children: [
                {
                  path: 'reviewerDashboard',
                  element: <ReviewerDashboardLandingPage />,
                },
                {
                  path: 'ticketing',
                  element: <TicketingLanding />,
                },
                {
                  path: ':review/distribution',
                  element: <DistributionPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
